import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NavbarWrapper from '../../common/components/Navbar';
import Drawer from '../../common/components/Drawer';
import Box from '../../common/components/Box';
import HamburgMenu from '../../common/components/HamburgMenu';
import Container from '../../common/components/UI/Container';
import { DrawerContext } from '../../common/contexts/DrawerContext';
import ScrollSpyMenu from '../../common/components/ScrollSpyMenu';
import { __ } from '../../common/utils/LanguageHelper';
import Button from '../../common/components/Button';
import { Link, useIntl } from 'gatsby-plugin-intl';
import Image from '../../common/components/Image';

import LogoImage from '../../common/assets/image/logo.png';

const Navbar = ({
    isHomePage,
    navbarStyle,
    menuWrapper,
    button,
    outlineBtnStyle,
}) => {
    const { state, dispatch } = useContext(DrawerContext);
    const [accessToken, setAccessToken] = useState('');
    const [domain, setDomain] = useState('');
    const { locale } = useIntl();

    // Toggle drawer
    const toggleHandler = () => {
        dispatch({
            type: 'TOGGLE',
        });
    };

    const MENU_ITEMS = [
        {
            label: __('الرئيسية', 'Home'),
            path: isHomePage ? '#banner_section' : '/',
            staticLink: !isHomePage,
            offset: '70',
        },
        {
            label: __('مميزاتنا', 'Our Features'),
            path: isHomePage ? '#site_features' : '/',
            staticLink: !isHomePage,
            offset: '70',
        },
        {
            label: __('قصة أواصل', 'Awasul story'),
            path: isHomePage ? '#Awasul_story' : '/',
            staticLink: !isHomePage,
            offset: '70',
        },
        {
            label: __('الخدمات', 'Services'),
            path: isHomePage ? '#pricing' : '/',
            staticLink: !isHomePage,
            offset: '70',
        },
        {
            label: __('العملاء', 'Clients'),
            path: '/client',
            staticLink: true,
            offset: '70',
        },
        {
            label: __('أخبار المنصة', 'News'),
            path: '/news',
            staticLink: true,
            offset: '70',
        },
        {
            label: __('تواصل معنا', 'Contact us'),
            path: '/contact',
            staticLink: true,
            offset: '70',
        },
    ];

    useEffect(() => {
        setDomain(localStorage.getItem('domain'));
        setAccessToken(localStorage.getItem('access_token'));
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('tenant_id');
        localStorage.removeItem('user_email');
        localStorage.removeItem('domain');
        localStorage.removeItem('access_token');
        document.location.reload(true);
    };

    const RenderLoginButton = (styles) => (
        <Button
            title={
                accessToken
                    ? __('الذهاب للمنصة', 'Go to platform')
                    : __('تسجيل الدخول', 'Login')
            }
            {...button}
            {...styles}
        />
    );

    const RenderNavButtons = ({ btn, outline, langBtn, logoutBtn }) => (
        <>
            <Link to="/job">
                <Button
                    title={__('انضم لفريق عمل أواصل', 'Join Awasul team')}
                    {...outlineBtnStyle}
                    {...outline}
                    variant="outlined"
                />
            </Link>

            {accessToken ? (
                <a
                    href={`http://${domain}.awasul1.com/?token=${accessToken}&lang=${locale}`}
                >
                    {RenderLoginButton(btn)}
                </a>
            ) : (
                <Link to="/login">{RenderLoginButton(btn)}</Link>
            )}

            {!!accessToken && (
                <span
                    onClick={handleLogout}
                    style={{
                        cursor: 'pointer',
                        color: '#7e53a7',
                        ...logoutBtn,
                    }}
                >
                    {__('تسجيل الخروج', 'Logout')}
                </span>
            )}

            <a href={__('/en', '/')} style={{ color: '#7e53a7', ...langBtn }}>
                {__('ENGLISH', 'اللغة العربية')}
            </a>
        </>
    );

    return (
        <NavbarWrapper {...navbarStyle}>
            <Container>
                <Box className="nav-row">
                    <Link to="/">
                        <Image src={LogoImage} alt="Awasul logo" />
                    </Link>

                    <ScrollSpyMenu
                        className="main_menu"
                        menuItems={MENU_ITEMS}
                        offset={-70}
                    />
                    <div className="buttonsWrapper">
                        <RenderNavButtons
                            langBtn={{
                                position: 'absolute',
                                left: __('50px', 'auto'),
                                right: __('auto', '50px'),
                                marginTop: 5,
                            }}
                        />
                    </div>
                    <Box {...menuWrapper}>
                        <Drawer
                            width="420px"
                            placement="right"
                            drawerHandler={<HamburgMenu barColor="#fe6b02" />}
                            open={state.isOpen}
                            toggleHandler={toggleHandler}
                        >
                            <ScrollSpyMenu
                                className="mobile_menu"
                                menuItems={MENU_ITEMS}
                                drawerClose={true}
                                offset={-100}
                            />
                            <RenderNavButtons
                                btn={{
                                    mr: 0,
                                    ml: 0,
                                    mb: '10px',
                                    height: '45px',
                                    width: '100%',
                                }}
                                outline={{
                                    mb: '10px',
                                    width: '100%',
                                    height: '45px',
                                }}
                                langBtn={{
                                    marginTop: 10,
                                    fontSize: 15,
                                    textAlign: 'center',
                                }}
                                logoutBtn={{
                                    padding: '10px',
                                    fontSize: 15,
                                    border: '1px solid rgb(126 83 167 / 30%)',
                                    borderRadius: 5,
                                    textAlign: 'center',
                                }}
                            />
                        </Drawer>
                    </Box>
                </Box>
            </Container>
        </NavbarWrapper>
    );
};

Navbar.propTypes = {
    navbarStyle: PropTypes.object,
    logoStyle: PropTypes.object,
    button: PropTypes.object,
    row: PropTypes.object,
    menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
    navbarStyle: {
        className: 'hosting_navbar',
        minHeight: '70px',
        display: 'block',
    },
    logoStyle: {
        maxWidth: '130px',
    },
    button: {
        type: 'button',
        fontSize: '13px',
        fontWeight: '600',
        color: 'white',
        borderRadius: '4px',
        pl: '15px',
        pr: '15px',
        ml: '17px',
        mr: '17px',
        colors: 'primaryWithBg',
        minHeight: 'auto',
        height: `${1}`,
    },
    outlineBtnStyle: {
        minHeight: 'auto',
        fontSize: '13px',
        fontWeight: '500',
        borderColor: 'rgba(82, 104, 219, 0.2)',
        color: '#5167db',
        ml: '0',
        mr: '0',
    },
    menuWrapper: {
        flexBox: true,
        alignItems: 'center',
    },
};

export default Navbar;
