import React from "react";
import ContainerWrapper from "./style";

const Container = ({ children, className, fullWidth, mobileGutter, width, ...rest }) => {
    // Add all classs to an array
    const addAllClasses = ["container"];
    // className prop checking
    if (className) {
        addAllClasses.push(className);
    }

    return (
        <ContainerWrapper
            className={addAllClasses.join(" ")}
            fullWidth={fullWidth}
            width={width}
            mobileGutter={mobileGutter}
            {...rest}>
            {children}
        </ContainerWrapper>
    );
};

export default Container;
